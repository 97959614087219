import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

const blogLang = window.theme.blog_id + window.theme.lang;

document.querySelector('html').classList.remove('alerts-bar-active');

if (window.sessionStorage.getItem('alertBarClosed' + blogLang)) {
  domReady(() => {
    vtxCloseBar();
  });
} else {
  fetch(window.theme.alerts_path + '?' + Date.now())
    .then((response) => {
      if (response.ok) {
        return response.text();
      }
      return Promise.reject('Error: ' + response.status);
    })
    .then((html) => {
      domReady(() => {
        vtxInitBar(html);
      });
    })
    .catch((error) => {
      console.log('error: ', error);
      domReady(() => {
        vtxCloseBar();
      });
    });
}

function vtxCloseBar() {
  document.querySelector('html').classList.remove('alerts-bar-active');
  const alertsBar = document.querySelector('#alerts-bar');
  if (alertsBar) {
    alertsBar.classList.remove('visible');
  }
}

function vtxInitBar(html) {
  if (!html) {
    return;
  }
  const alertsBar = document.querySelector('#alerts-bar');
  if (alertsBar) {
    const alertsBarCarousel = document.querySelector('.alerts-bar__carousel');
    if (alertsBarCarousel) {
      alertsBarCarousel.innerHTML = html;
      let alertsBarItems = document.querySelectorAll('.alert-bar__columns');
      let alertSwiper = null;
      //First pass through items to eliminate closed alerts and out of bound alerts
      if (alertsBarItems && alertsBarItems.length) {
        const currTimestamp = Math.floor(Date.now() / 1000);
        alertsBarItems.forEach((item) => {
          if (item.dataset.start && item.dataset.start > currTimestamp) {
            //too soon
            item.remove();
          } else if (item.dataset.end && item.dataset.end < currTimestamp) {
            //too late
            item.remove();
          }
          //otherwise we let it there, no item.dataset.end or start means all the time
        });
        //The final items
        alertsBarItems = document.querySelectorAll('.alert-bar__columns');
      }
      if (0 !== alertsBarItems.length) {
        document.querySelector('html').classList.add('alerts-bar-active');
      } else {
        vtxCloseBar();
        return;
      }

      if (1 < alertsBarItems.length) {
        alertSwiper = new Swiper('.alerts-bar__carousel', {
          slidesPerView: 1,
          loop: true,
          autoplay: {
            delay: 8000,
          },
          pagination: {
            el: '.alerts-bar__pagination',
            clickable: true,
          },
        });

        document
          .getElementById('toggleAnimations')
          .addEventListener('change', function () {
            if (document.getElementById('toggleAnimations').checked) {
              alertSwiper.autoplay.stop();
            } else {
              alertSwiper.autoplay.start();
            }
          });
      }

      const alertsCloseBtn = document.querySelector('.alertsBarCloseBtn');
      if (alertsCloseBtn) {
        alertsCloseBtn.addEventListener('click', function () {
          if (alertSwiper) {
            alertSwiper.destroy();
          }
          vtxCloseBar();
          window.sessionStorage.setItem('alertBarClosed' + blogLang, true);
        });
      }
    }
  }
}
